import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import PatternIframe from "../component/PatternIframe.jsx";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineNotification = makeShortcode("InlineNotification");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl="https://www.figma.com/file/4iZW5otFmH6Bskg3s4RMMi/Pattern-Dashboard?type=design&node-id=22%3A2&t=RrzrMnyvqRbOmPlQ-1" codeUrl="https://gitlab.playcourt.id/telkomdev/legion-pattern-dashboard/-/tree/phoenix" mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <p>{`The use case of dashboards contains a collection of dashboard study cases that are commonly found and collected from many sites. Dashboard use cases will provide examples of component combinations in the dashboard according to its needs and functions.`}</p>
    <InlineNotification mdxType="InlineNotification">
      <p><strong parentName="p">{`Use case pattern:`}</strong>{` In this pattern, various information from dashboards are presented based on their function and feature. Please be aware on every page, there are Pros & Cons that we should read carefully.`}</p>
    </InlineNotification>
    <br />
    <AnchorLinks small mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Case : Sales Monitoring Dashboard</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Case : Customer Service Monitoring Dashboard</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Case : Web Analytic Dashboard</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Case : Product Performance Management Dashboard</AnchorLink>
    </AnchorLinks>
    <div className="divi" />
    <InlineNotification kind='warning' mdxType="InlineNotification">
      <p><strong parentName="p">{`Customize pattern:`}</strong>{` In this pattern, various use cases of dashboards are presented based on each function. We will add the use cases periodically.`}</p>
    </InlineNotification>
    <h2>{`Case : Sales Monitoring Dashboard`}</h2>
    <PatternIframe url="https://legion-pattern-dashboard-telkom-design.vercel.app/dashboard/sales-monitoring" breakpoints={["lg", "xl"]} mdxType="PatternIframe" />
    <p><strong parentName="p">{`User Story`}</strong>{`: As an admin, I want to monitor and manage the sales performance and activities of the salesperson in the company`}</p>
    <p><strong parentName="p">{`Requirement Scenario/Acceptance Criteria`}</strong></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Provide an overview of the real-time, day-to-day progress of sales activities.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Provide information on sales goals and their progress (target transactions, orders, retention customers, etc).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Provide information on sales reports and download options.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Provide the history of the transaction process (Profit, Earnings, Expenses, etc.).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Provide more transparency by adding information such as a tooltip, filters, and pagination.`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`Pros and Cons`}</strong></p>
    <DoDontRow mdxType="DoDontRow">
      <DoDont type="do" text="This pattern enables users to efficiently track and monitor indicators of activities." aspectRatio="2:1" colLg="6" mdxType="DoDont">
      </DoDont>
      <DoDont type="dont" text="This dashboard does not suitable for users who want to see analytical information." aspectRatio="2:1" colLg="6" mdxType="DoDont">
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Case : Customer Service Monitoring Dashboard`}</h2>
    <PatternIframe url="https://legion-pattern-dashboard-telkom-design.vercel.app/dashboard/customer-service" breakpoints={["lg", "xl"]} mdxType="PatternIframe" />
    <p><strong parentName="p">{`User Story`}</strong>{`: As a customer service agent, I want to monitor and manage the activities of completing the support for customers’ needs`}</p>
    <p><strong parentName="p">{`Requirement Scenario/Acceptance Criteria`}</strong></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The data view focuses on the day-to-day activity of customers’ services.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Provide total data on the support request made by the customers.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Provide total data of complaints received & resolved.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Provide the detail of each support response time.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Provide the average response time.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Provide customer satisfaction feeadback.`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`Pros and Cons`}</strong></p>
    <DoDontRow mdxType="DoDontRow">
      <DoDont type="do" text="This pattern is suitable for tracking and creating actionable information." aspectRatio="2:1" colLg="6" mdxType="DoDont">
      </DoDont>
      <DoDont type="dont" text="The data visualization in this pattern is not varied, focusing on presenting simple data." aspectRatio="2:1" colLg="6" mdxType="DoDont">
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Case : Web Analytic Dashboard`}</h2>
    <PatternIframe url="https://legion-pattern-dashboard-telkom-design.vercel.app/dashboard/website-analytic" breakpoints={["lg", "xl"]} mdxType="PatternIframe" />
    <p><strong parentName="p">{`User Story`}</strong>{`: As a website user, I want to display and track web performance metrics of my website (such as traffic, bounce rate, conversion rate, and session duration)`}</p>
    <p><strong parentName="p">{`Requirement Scenario/Acceptance Criteria`}</strong></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Provide overview of website audience (number of users, bounce rate, page views, and session duration).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Provide detail information of website session by channel.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Provide detail information of top 5 page most viewed.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Provide option to view and filter the data based on selected date`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Provide option to download or easily share the full reports (convert to excel, pdf, etc)`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`Pros and Cons`}</strong></p>
    <DoDontRow mdxType="DoDontRow">
      <DoDont type="do" text="This pattern enables showing real-time traffic data and creating customization reports based on the collected data." aspectRatio="2:1" colLg="6" mdxType="DoDont">
      </DoDont>
      <DoDont type="dont" text="Avoid this pattern to create an actionable dashboard, this pattern is intended to display information to provide insights for decision-making." aspectRatio="2:1" colLg="6" mdxType="DoDont">
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Case : Product Performance Management Dashboard`}</h2>
    <PatternIframe url="https://legion-pattern-dashboard-telkom-design.vercel.app/dashboard/product-performance" breakpoints={["lg", "xl"]} mdxType="PatternIframe" />
    <p><strong parentName="p">{`User Story`}</strong>{`: As an admin, I want to gather and analyze the data of performance and information about my product’s growth and success`}</p>
    <p><strong parentName="p">{`Requirement Scenario/Acceptance Criteria`}</strong></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Provide overview of all product KPI’s (Customers growth, Conversions, and Revenue.).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Provide total number & detailed information of transactions periodically (weekly, monthly).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Provide information of target progress and achievements.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Provide ability to filter & monitor trends based on (Revenue, Profit, Expenses).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Provide comparison of cashflow from all transactions.`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`Pros and Cons`}</strong></p>
    <DoDontRow mdxType="DoDontRow">
      <DoDont type="do" text="This pattern provides the ability to track the product's KPI consistently in real-time updates." aspectRatio="2:1" colLg="6" mdxType="DoDont">
      </DoDont>
      <DoDont type="dont" text="There's might be an individual or team learning curve according to the KPI used in the dashboard." aspectRatio="2:1" colLg="6" mdxType="DoDont">
      </DoDont>
    </DoDontRow>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      